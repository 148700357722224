import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import ReferenceBlock from "../components/template-partials/reference-block"
import FooterCrown from "../components/template-partials/footer-crown"

import { AnchorLink } from "gatsby-plugin-anchor-links"

import magnifyBg from "../images/magnifying_glass.svg"
import hrImage from "../images/hr-image.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

const IndexPage = () => (
  <Layout isHome={true} route="home">
    <Seo
      title="Home"
      description="Learn how to suspect systemic mastocytosis (SM) through testing and identification of SM symptoms."
    />
    <div className="row align-center">
      <div className="columns small-12 large-12 text-center">
        <h1 className="color--purple text--watch-quinn homepage__hero-text font48">
          Is systemic mastocytosis
          <br className="show-for-large" /> showing its hand?{" "}
        </h1>
        <p className="h4 text-center color--purple lg_mt-3 mt-3 medium mobileBodyP">
          Recognizing the symptoms is just the beginning. Systemic mastocytosis
          (SM) can deal patients a range of unpredictable and
          <br className="show-for-large" /> debilitating symptoms that affect
          multiple organ systems—such as{" "}
          <span className="powder">skin lesions</span>,{" "}
          <span className="powder">anaphylaxis</span>,{" "}
          <span className="powder">diarrhea</span>, and sometimes organ damage
          <sup>1</sup>
        </p>
      </div>
    </div>
    <div className="row align-center lg_mt-3 mt-2">
      <div className="columns large-10">
        <div
          className="purple-card text-center purple-card--magnify"
          style={{ backgroundImage: `url(${magnifyBg})` }}
          title="Magnifying glass"
        >
          <p className="h2 semiBold">
            It’s time for SM to lay all <br className="show-for-large" />
            its cards on the table
          </p>
          <p>
            Earlier identification of disease and active management of
            <br className="show-for-large" /> potentially life-threatening
            symptoms are critical<sup>2,3</sup>
          </p>
          <AnchorLink
            to="/about-sm/"
            title="Learn About SM"
            className="btn btn--mustard lg_mt-1 mt-1 button_slim"
          >
            LEARN ABOUT SM <FontAwesomeIcon icon={faAngleRight} />
          </AnchorLink>
        </div>
      </div>
    </div>
    <div className="row align-center lg_mt-6 mt-1 mb-4 pt-4 lg_mb-6 align-middle home_suspect">
      <p className="h1 color--purple text--watch-quinn text-center">
        Suspect SM?
      </p>
      {/* <img
        src={dividerImg}
        alt=""
        width="100%"
        height="auto"
        className="lg_mt-2 mt-2 show-for-medium"
      /> */}

      <div className="hr-image-divider">
        <div className="line"></div>
        <img src={hrImage} alt="" />
        <div className="line"></div>
      </div>

      <div className="three_col_div">
        <div className="three_col_inner">
          <h3 className="text--semibold purple">
            How might SM present <br className="show-for-large" /> in my
            patient?
          </h3>
          <a href="/sm-profiles-case-studies/">
            <span>
              EXPLORE HYPOTHETICAL
              <br className="show-for-large" /> PATIENT CASES
            </span>{" "}
            <FontAwesomeIcon icon={faAngleRight} />
          </a>
        </div>

        <div className="three_col_inner">
          <h3 className="text--semibold purple">
            How can I confirm an SM <br className="show-for-large" /> diagnosis?
          </h3>
          <a href="/diagnosing-sm/">
            <span>
              DIAGNOSING SM,
              <br className="show-for-large" /> INCLUDING KIT D816V
              <br className="show-for-large" /> TESTING
            </span>{" "}
            <FontAwesomeIcon icon={faAngleRight} />
          </a>
        </div>

        <div className="three_col_inner ">
          <h3 className="text--semibold purple">
            Have unanswered questions <br className="show-for-large" /> about
            SM?
          </h3>
          <a href="/sm-resources/faqs/">
            <span>REVIEW FAQs</span> <FontAwesomeIcon icon={faAngleRight} />
          </a>
        </div>
      </div>
    </div>
    <div className="lg-mt-7 mt-1">
      <FooterCrown type="home" />
    </div>
    <ReferenceBlock>
      <ol className="color--purple">
        <li>
          Gilreath JA et al. <em>Clin Pharmacol.</em> 2019;11:77-92.
        </li>
        <li>
          Shomali W, Gotlib J. <em>Hematology.</em> 2018;2018(1):127-136.
        </li>
        <li>
          Jensen B et al. <em>J Clin Nurs.</em> 2019;28(7-8):1114-1124.
        </li>
      </ol>
    </ReferenceBlock>
  </Layout>
)

export default IndexPage
